import React from 'react';
import { Link } from 'gatsby';
import { Menu, Header, Image, Icon } from 'semantic-ui-react';

import Logo from '../data/logo.png';

const menuItems = [
  {
    title: 'Featured Projects',
    slug: '/',
  },
  {
    title: 'How It Works',
    slug: '/how-it-works',
  },
  {
    title: 'About',
    slug: '/about',
  },
  {
    title: 'Contact',
    slug: '/contact',
  }
];

const activeStyle = {
  borderBottom: "solid 2px #414a4c",
};

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.onToggleNav = this.onToggleNav.bind(this);
  }

  onToggleNav() {
    const { open } = this.state;

    this.setState({ open: !open })
  }

  render() {
    const { open } = this.state;

    return (
      <div className="mobile-nav">
        <div className="mobile-header">
          <Icon className="mobile-menu-icon" name="bars" size="big" onClick={this.onToggleNav}/>
          <Image src={Logo} className="mobile-menu-logo"/>
          <h3>Full Scope Web</h3>
        </div>
        {open && (
          <div className="mobile-overlay">
            <Icon className="mobile-menu-icon-exit" name="x" size="huge" onClick={this.onToggleNav}/>
            {
              menuItems.map(({ slug, title }) => (
                <Link
                  onClick={this.onToggleNav}
                  to={slug}
                  activeStyle={activeStyle}
                >
                  {title}
                </Link>
                  ))}
          </div>
        )}
      </div>
    )
  }
};
