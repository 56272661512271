import AWS from 'aws-sdk';

const emailTemplate = (name, email, content) => ({
  Destination: {
    /* required */
    ToAddresses: ["nickloeper1@gmail.com", "fullscopeweb@gmail.com", "ameetk96@gmail.com"]
  },
  Message: {
    Body: {
      Text: {
        Charset: "UTF-8",
        Data: `
          ${name} has reached out to you via Full Scope Web contact form
          
          "${content}"
          Email: ${email}
          
          By clicking reply to this email, the contacts email will be prepopulated 
          `
      }
    },
    Subject: {
      Charset: "UTF-8",
      Data: `[Full Scope Website] Contact Request`
    }
  },
  Source: "Full Scope Admin <fullscopeweb@gmail.com>",
  ReplyToAddresses: [email]
});

const subscriptionCallback = ({ name, email, content }) =>
  new AWS.SES({ apiVersion: "2010-12-01" })
    .sendEmail(emailTemplate(name, email, content))
    .promise();

export default subscriptionCallback;