import React from 'react';
import { Link } from 'gatsby';
import { Menu, Header, Image } from 'semantic-ui-react';

import Logo from '../data/logo.png';

const menuItems = [
  {
    title: 'Featured Projects',
    slug: '/',
  },
  {
    title: 'How It Works',
    slug: '/how-it-works',
  },
  {
    title: 'About',
    slug: '/about',
  },
  {
    title: 'Contact',
    slug: '/contact',
  }
];

const activeStyle = {
  borderBottom: "solid 3px #414a4c",
};

export default ({ location }) => {
  return (
    <Menu className="desktop-nav" borderless>
      <Menu.Item as={Link} to="/">
        <Image src={Logo} height={50}/>
        <h3 className="desktop-nav-title">Full Scope Web</h3>
      </Menu.Item>
      <Menu.Menu position="right">
        {menuItems.map(({ title, slug }) => (
          <Menu.Item as={Link} to={slug} activeStyle={activeStyle}>
            {title}
          </Menu.Item>
        ))}
      </Menu.Menu>
    </Menu>
  )
}