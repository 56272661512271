import React from 'react';

import '../style/navigation.css';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

export default ({ location }) => {
  return (
    <React.Fragment>
      <DesktopNav />
      <MobileNav />
    </React.Fragment>
  )
}