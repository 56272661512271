import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql, Link } from 'gatsby';

import Navigation from './Navigation';
import Footer from './Footer';

import 'semantic-ui-less/semantic.less';

export default ({ children, data }) => (
  <div style={{ overflowX: 'hidden' }}>
    <Navigation />
    <div className="page-container">{children}</div>
    <Footer />
  </div>
);
