import React from 'react';
import AWS from 'aws-sdk';
import Helmet from 'react-helmet';
import { Header, Form, Grid, Button, Divider, Icon } from 'semantic-ui-react';

import '../style/contact.css';
import Layout from '../components/Layout';
import emailService from '../utils/email';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBot: '',
      name: '',
      email: '',
      content: '',
      sent: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTextareaChange = this.handleTextareaChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    AWS.config.update({
      region: "us-east-1",
      credentials: new AWS.Credentials(
        process.env.AWS_ACCESS_KEY_ID,
        process.env.AWS_SECRET_ACCESS_KEY
      )
    });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name || 'content']: value })
  }

  handleTextareaChange(e) {
    this.setState({ content: e.target.value })
  }

  onSubmit() {
    if (this.state.isBot.length === 0) {
      emailService(this.state).then(res => this.setState({ sent: true }));
    }
  }

  render() {
    return (
      <Layout>
        <Helmet
          title={`Full Scope Web | Contact`}
          meta={[
            { name: 'description', content: 'Contact Full Scope Web by request form email to get a quote or discuss a potential project.' },
          ]}
        />
        <div className="contact">
          <Header as="h3">Contact Us</Header>
          <div>
            Need a website? We can probably build it!
            Give us a call at <strong>(617) 334-5366</strong> or submit a project request below.
          </div>
          <Grid stackable>
            <Grid.Column width={16} textAlign="center">
              <div className="contact-form-container">
                <Form onSubmit={this.onSubmit}>
                  <Form.Input
                    style={{ display: "none" }}
                    onChange={this.handleChange}
                    fluid
                    name="isBot"
                    value={this.state.isBot}
                    placeholder="isBot"
                  />
                  <Form.Input
                    onChange={this.handleChange}
                    fluid
                    name="name"
                    required
                    value={this.state.name}
                    placeholder="Your Name*"
                  />
                  <Form.Input
                    onChange={this.handleChange}
                    fluid
                    name="email"
                    required
                    value={this.state.email}
                    placeholder="Your Email*"
                    type="email"
                  />
                  <textarea
                    required
                    onChange={this.handleTextareaChange}
                    className="contact-form-textarea"
                    name="content"
                    value={this.state.content}
                    placeholder="Your Message*"
                  />
                  <Button type="submit" fluid size="small">
                    <h5>{this.state.sent ? "SENT" : "SEND MESSAGE"}</h5>
                  </Button>
                </Form>
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </Layout>
    );
  }
};
